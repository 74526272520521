import React from "react";
import Layout from "../../../components/Layout";
import Episode from "../../../components/Podcast/Episode";
import podcastEpisodes, { pageKeywords } from "../../../utils/podcastEpisodes";

export default ({ location }) => {
  const lang = "ja";

  // Page setting
  const episodeNo = 53;
  const pageImage = "";
  const episode = podcastEpisodes.find(ep => ep.id === episodeNo);
  const episodeContent = (
    <>
      <ul className="basicList">
        <li>アメリカの医療に触れなければならないときがきた</li>
        <li>銀歯とれた事件</li>
        <li>おいしいものを食べたときに舌を噛む情けなさ</li>
        <li>保険システムさっぱり分からない</li>
        <li>デンタルケアは基本的に保障されない</li>
        <li>日本語が通じる歯医者？家から近い歯医者？</li>
        <li>英語の不安＋お金の不安</li>
        <li>治療の前に確認した2つの質問</li>
        <li>結局いくらかかったの？</li>
      </ul>
    </>
  );

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={episode.title}
      pageSlug={episode.slug}
      pageKeywords={pageKeywords}
      pageDescription={episode.description}
      pageImage={pageImage}
    >
      <Episode
        episodeNo={episodeNo}
        episodeTitle={episode.title}
        episodeDescription={episode.description}
        episodeContent={episodeContent}
        episodeURL={episode.embed}
      />
    </Layout>
  );
};
